import React from 'react';
import { Container, Row, Col, Stack } from 'react-bootstrap';
import './Profile.css';
import { STIPhp, STIHtml5, STITypescript } from '@icongo/sti';
import { ICDocLogoJs, ICDocLogoPython, ICPServer, ICPLinux, ICPIntelligent, ICDocInformationProtection, ICPPixelatedArrow, ICOLoadTesting, ICDocApiApps } from '@icongo/ic';
import { LGDart, LGNodejsIcon, LGFlutter, LGReact, LGSocketIo, LGGooglePlayIcon, LGGodotIcon, LGLua, LGTelegram } from '@icongo/lg';
import { SKExpressJsDark, SKMongoDb, SKSqLite, SKFigmaDark, SKMySqlDark, SKCss, SKIllustrator, SKGithubDark } from '@icongo/sk';
import { IUInstagram } from '@icongo/iu/lib/IUInstagram';
import { VVFlame } from '@icongo/vv';
import me from './me.jpg';


const Profile = () => {
    return (
        <Container className="mt-5 m-2 m-sm-1 m-md-5">
            <Row>
                <Row className="mb-5">
                    <Col xs lg="2">
                        <div className="profile-icon">

                            <img src={me} alt="User Avatar" class="avatar rounded-circle border" />
                        </div>
                    </Col>
                    <Col md="auto">
                        <h1>Jumanazarov Umidbek</h1>
                        <p className="text-muted">Assalomu Alaykum I'm Full Stack, Mobile, Developer</p>
                        <Stack direction="horizontal" gap={1} className="links">
                            <a href="#https://t.me/xapiuz"><Stack direction="horizontal" gap={1}> <LGTelegram /> Telegram</Stack></a>
                            <a href="https://github.com/UmidbekJumanazarov"><Stack direction="horizontal" gap={1}> <SKGithubDark /> Github</Stack></a>
                            <a href="https://www.instagram.com/xapi.uz"><Stack direction="horizontal" gap={1}> <IUInstagram /> Instagram</Stack></a>

                        </Stack>
                    </Col>
                </Row>
                <Row className="mb-5" >
                    <Col xs lg="2">
                        <Stack className="heda" direction="horizontal" gap={1}><ICDocInformationProtection /> <span> ABOUT ME</span></Stack>
                    </Col>
                    <Col lg={10}>
                        <div className="text-muted">I am a Full Stack and Mobile Developer with expertise in Flutter,
                            specializing in creating dynamic and responsive mobile applications.
                            With a strong foundation in web and mobile technologies, I’m focused on delivering high-quality, user-centered solutions.
                            My skills also extend to game development as a hobby, adding a creative
                            edge to my technical work and broadening my perspective in software development.</div>

                    </Col>
                </Row>
                <Row className="mb-5">
                    <Col xs lg="2">
                        <Stack className="heda" direction="horizontal" gap={1}> <ICDocApiApps /><span> PROJECTS</span></Stack>
                    </Col>
                    <Col lg={10}>
                        <div className="links">
                            <a href="https://play.google.com/store/apps/details?id=com.xapi.islam_tver"> <Stack direction="horizontal" gap={1}>
                                <LGGooglePlayIcon /><span>Islam Tver</span>
                            </Stack></a>
                        </div>
                        <p className="text-muted">
                            Role: Developer <br />
                            Tech: Flutter (MVC architecture)<br />
                            Description: Offline prayer timetable app for Tver, verified . Accurate and accessible without internet.
                        </p>
                        <div className="links">
                            <a href="https://play.google.com/store/apps/details?id=com.xapi.sajda_uz"><Stack direction="horizontal" gap={1}>
                                <LGGooglePlayIcon /><span>Sajda Uz</span>
                            </Stack></a>
                        </div>
                        <p className="text-muted">
                            Role: Developer <br />
                            Tech: Flutter (MVC architecture)<br />
                            Description: Offline prayer timetable app for Uzbekistan,Accurate and accessible without internet.
                        </p>

                    </Col>
                </Row>
                <Row className="mb-5">
                    <Col xs lg="2">
                        <Stack className="heda" direction="horizontal" gap={1}><ICPIntelligent /> <span>SKILLS</span></Stack>
                    </Col>
                    <Col lg={10}>
                        <h6>Languages</h6>
                        <div className="icons"><STIPhp /> <ICDocLogoJs /> <STITypescript />  <LGDart />  <LGNodejsIcon />  <ICDocLogoPython /></div><br />
                        <h6>Frameworks</h6>
                        <div className="icons"><LGFlutter /> <SKExpressJsDark /> <LGReact /> <LGSocketIo /> </div><br />
                        <h6>Databases</h6>
                        <Stack className="text-muted" direction="horizontal" gap={2}><SKMySqlDark /> MySQL <SKSqLite /> SqLite <SKMongoDb /> MongoDb</Stack><br />
                        <h6>Other</h6>
                        <Stack className="text-muted" direction="horizontal" gap={2}><ICPServer /> VDS  <ICPLinux /> Linux  <SKGithubDark />GitHub <SKCss /> CSS3 <STIHtml5 /> HTML </Stack>

                    </Col>
                </Row>

                <Row className="mb-3">
                    <Col xs lg="2">
                        <Stack className="heda" direction="horizontal" gap={1}><ICOLoadTesting /> <span>INTERESTS</span></Stack>
                    </Col>
                    <Col lg={10}>
                        <h6>Reading</h6>
                        <Stack className="text-muted" direction="horizontal" gap={2}> Grokking Algorithms, Clean Architecture, Clean Code,  Robert C. Martin</Stack><br />
                        <h6>Game Development</h6>
                        <Stack className="text-muted" direction="horizontal" gap={2}> <VVFlame /> Flame <LGGodotIcon /> Godot <LGLua />Defold  </Stack><br />
                        <h6>Graphics</h6>
                        <Stack className="text-muted" direction="horizontal" gap={2}> <SKFigmaDark />Figma <SKIllustrator />Adobe Illustrator, <ICPPixelatedArrow />Aseprite</Stack>

                    </Col>
                </Row>
                <Row className="mb-3"></Row>
            </Row>
        </Container>
    );
};

export default Profile;
